import React from 'react';
import styled from 'styled-components';
import * as Util from '../Util';

export function getPaddingTop(navbar) {
  if (!navbar) {
    return 'var(--topNavBarHeight)';
  } else if (navbar.type === 'global') {
    return 'var(--topNavBarHeight)';
  } else if (navbar.type === 'solid') {
    return 'var(--topNavBarHeight)';
  } else {
    return '0px';
  }
}

export default function MktNavbar(props) {
  const {solid = false, navbar} = props;

  if (!navbar || navbar.type === 'global') {
    return null;
  }

  return (
    <Wrapper
      solid={navbar?.type === 'solid' || solid}
      ext_style={navbar.ext_style}>
      <div className="content">
        <div className="title">
          <img src={navbar.icon_image} alt="hishop" />
        </div>
        <div style={{flex: 1}} />
        {(navbar.links || []).map((item, idx) => {
          const last = idx === (navbar.links || []).length - 1;
          return (
            <a
              key={item.url}
              href={item.url}
              className={last ? 'btn' : 'link'}
              onClick={(e) => {
                e.preventDefault();
                Util.onLinkClick(item.url);
              }}>
              {item.text || 'Action'}
            </a>
          );
        })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  z-index: 1000;
  background-color: ${(props) => (props.solid ? 'white' : 'transparent')};
  box-shadow: ${(props) =>
    props.solid ? '0 2px 4px rgba(0,0,0,0.2)' : 'none'};
  transition: all 300ms;

  & > .content {
    flex: 1;
    max-width: 1160px;
    margin: 0px auto;
    padding: 0 20px;
    display: flex;
    align-items: center;

    & > .title {
      & > img {
        width: 48px;
        height: 48px;
        object-fit: contain;
      }
    }

    & > .link {
      color: ${(props) => (props.solid ? '#666' : 'white')};
      text-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
      margin: 0 20px;
    }

    & > .btn {
      background-color: white;
    }
  }

  ${(props) => props.ext_style}
`;
